import axios from "axios"

const Http = axios.create({
  baseURL: "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})

document.addEventListener("turbo:load", () => {
  const el = document.querySelector('meta[name="csrf-token"]')
  const token = el && el.getAttribute("content")

  Http.defaults.headers.common["X-CSRF-Token"] = token
})

export default Http
